/** @module navigation */

import $ from 'jquery';

/**
 * Handles functionality of the primary navigation component.
 * Above a given breakpoint, it acts as a dropdown-menu, below an accordion-menu.
 * As a dropdown-menu, all submenus delay closing for a given duration, except when other submenus are opened.
 * As an accordion-menu, opening and closing happens immediately, but also only one submenu of the same level can be open concurrently.
 *
 * @see https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle
 * @see https://www.w3.org/TR/wai-aria-practices/examples/disclosure/disclosure-navigation.html
 */
export class PrimaryNav {
    /** @type {Element[]} */
    #subMenus = null;

    /** @type {Set} */
    #openMenus = new Set();

    /** @type {WeakMap<Element,number>} */
    #subMenuClosingTimeouts = new WeakMap();

    /** @type {Element} */
    #primaryNav = null;

    /** @type {string} */
    #breakpoint = 'medium';

    /** @type {number} */
    #closingDelay = 1000;

    /** @type {boolean|string} */
    #expandInitially = 'below';

    /** @type {boolean} */
    #touch = false;

    /** @type {boolean} */
    #keyboard = false;

    /**
     * @param {object} [options={}] - Configuration options.
     * @param {Element|string} [options.primaryNav=null] - Either the element or the selector for the element that
     *  holds the primary navigation component. If omitted, the element is determined by selector
     *  <code>[data-primary-nav]</code>.
     * @param {string} [options.breakpoint='medium'] - Breakpoint to switch between dropdown- and accordion-menu.
     * @param {number} [options.closingDelay=1000] - Delay in <code>ms</code> for how long the closing of submenus should be suspended.
     * @param {boolean|string} [options.expandCurrentItemsInitially='below'] - Whether, and when, submenus holding the currently
     * active menu item should be expanded. Possible values are:
     * <ul>
     * <li><code>true</code>: Submenus are always expanded.</li>
     * <li><code>false</code>: Submenus are never expanded.</li>
     * <li><code>'below'</code>: Submenus are expanded below the breakpoint set by <code>options.breakpoint</code>.</li>
     * <li><code>'above'</code>: Submenus are expanded at and above the breakpoint set by <code>options.breakpoint</code>.</li>
     * </ul>
     */
    constructor({
        primaryNav = null,
        breakpoint = 'medium',
        closingDelay = 1000,
        expandCurrentItemsInitially = 'below',
    } = {}) {
        if (primaryNav && primaryNav instanceof Element) {
            this.#primaryNav = primaryNav;
        } else if (primaryNav && typeof primaryNav === 'string') {
            this.#primaryNav = document.querySelector(primaryNav);
        } else {
            this.#primaryNav = document.querySelector('[primary-nav]');
        }

        if (!this.#primaryNav) {
            throw '[PrimaryNav] No element found to attach to.';
        }

        if (breakpoint) {
            this.#breakpoint = breakpoint;
        }

        if (closingDelay) {
            this.#closingDelay = closingDelay;
        }

        if (expandCurrentItemsInitially !== null && typeof expandCurrentItemsInitially !== 'undefined') {
            this.#expandInitially = expandCurrentItemsInitially;
        }

        this._init();
    }

    /** @private */
    _init() {
        const listenerOptions = { passive: true };

        this.#primaryNav.querySelectorAll('[data-menu-item][data-has-submenu]').forEach((menuItem) => {
            // NOTE: when touching the menu-toggle-button, mouseenter and mouseleave events are also triggered.
            // order is touchstart -> mouseenter for the first touch and
            //          touchstart -> mouseleave -> mouseenter for all subsequent touches to different toggle-buttons.
            // touching the same toggle-button again does not trigger mouse-events.

            menuItem.addEventListener('touchstart', this._onSubmenuParentTouchStart.bind(this), listenerOptions);
            menuItem.addEventListener('mouseenter', this._onSubmenuParentMouseEnter.bind(this), listenerOptions);
            menuItem.addEventListener('mouseleave', this._onSubmenuParentMouseLeave.bind(this), listenerOptions);

            menuItem.addEventListener('keydown', this._onSubmenuKeyDown.bind(this), listenerOptions);
            menuItem.addEventListener('keyup', this._onSubmenuKeyUp.bind(this), listenerOptions);

            const submenuToggle = menuItem.querySelector('[data-submenu-toggle]');

            if (submenuToggle) {
                submenuToggle.addEventListener('click', this._onSubmenuToggleClick.bind(this), listenerOptions);
            }
        });

        this.#primaryNav.querySelectorAll('[data-menu-item]').forEach((menuItem) => {
            menuItem
                .closest('[data-menu]')
                .addEventListener('focusin', this._onSubmenuFocusIn.bind(this), listenerOptions);
            menuItem
                .closest('[data-menu]')
                .addEventListener('focusout', this._onSubmenuFocusOut.bind(this), listenerOptions);
        });

        $(window).on('changed.zf.mediaquery', this._onBreakpointChange.bind(this));

        this._eventuallyExpandSubmenusInitially();
    }

    /** @private */
    _toggleSubmenu(parentMenuItem) {
        if (parentMenuItem.dataset.expanded === 'true') {
            this._closeSubmenu(parentMenuItem);
        } else {
            this._openSubmenu(parentMenuItem);
        }

        if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
            this._sanitizeHeights();
        }
    }

    /** @private */
    _checkAlignment(parentMenuItem) {
        let menuParentDimensions = parentMenuItem.getBoundingClientRect(),
            subMenu = parentMenuItem.querySelector('[data-menu-sub]'),
            subMenuDimensions = subMenu.getBoundingClientRect(),
            level = Number(subMenu.dataset.menuSub);

        if (level === 1 && menuParentDimensions.left + subMenuDimensions.width > window.innerWidth) {
            parentMenuItem.classList.add('aligns-right');
        } else if (
            level === 2 &&
            menuParentDimensions.left + menuParentDimensions.width + subMenuDimensions.width > window.innerWidth
        ) {
            parentMenuItem.classList.add('aligns-right');
        } else if (level > 2) {
            // do nothing, alignment is inherited
        } else {
            parentMenuItem.classList.remove('aligns-right');
        }
    }

    /** @private */
    _openSubmenu(parentMenuItem) {
        const toggleButton = parentMenuItem.querySelector('[data-submenu-toggle]'),
            isDesktop = Foundation.MediaQuery.atLeast(this.#breakpoint);

        if (isDesktop && parentMenuItem.closest('[data-has-mega-menu]')) {
            // don't open submenus
            if (!('hasMegaMenu' in parentMenuItem.dataset)) {
                return;
            }

            this._closeForeignSubmenus(parentMenuItem);

            this.#primaryNav.classList.add('is-mega-menu-open');
        } else {
            this._closeForeignSubmenus(parentMenuItem);
        }

        if (toggleButton) {
            toggleButton.setAttribute('aria-expanded', true);
            toggleButton.querySelector('[data-submenu-toggle-label]').innerHTML =
                toggleButton.dataset.submenuToggleHideText;
        }

        parentMenuItem.classList.add('is-expanded');
        parentMenuItem.dataset.expanded = 'true';

        if (isDesktop === false) {
            const subMenu = parentMenuItem.querySelector('[data-menu-sub]');

            if (subMenu) {
                subMenu.style.display = 'block';
            }
        }

        this.#openMenus.add(parentMenuItem);
    }

    /** @private */
    _closeForeignSubmenus(parentMenuItem) {
        // close other submenus immediately, if they are no ancestors
        [...this.#openMenus]
            .filter((menu) => menu.contains(parentMenuItem) === false)
            .forEach(this._closeSubmenu.bind(this));
    }

    /** @private */
    _closeSubmenu(parentMenuItem) {
        const toggleButton = parentMenuItem.querySelector('[data-submenu-toggle]'),
            isDesktop = Foundation.MediaQuery.atLeast(this.#breakpoint);

        if (isDesktop && parentMenuItem.closest('[data-has-mega-menu]')) {
            if ('hasMegaMenu' in parentMenuItem.dataset) {
                this.#primaryNav.classList.remove('is-mega-menu-open');
            } else {
                return;
            }
        }

        if (toggleButton) {
            toggleButton.setAttribute('aria-expanded', false);
            toggleButton.querySelector('[data-submenu-toggle-label]').innerHTML =
                toggleButton.dataset.submenuToggleShowText;
        }

        if (isDesktop) {
            parentMenuItem.classList.remove('is-expanded');
            parentMenuItem.dataset.expanded = 'false';
        } else {
            const subMenu = parentMenuItem.querySelector('[data-menu-sub]');

            if (subMenu) {
                parentMenuItem.classList.remove('is-expanded');
                parentMenuItem.dataset.expanded = 'false';

                const listenerOptions = { once: true },
                    listener = () => {
                        if (parentMenuItem.dataset.expanded === 'false') {
                            subMenu.style.display = 'none';
                        }
                    };

                subMenu.removeEventListener('transitionend', listener, listenerOptions);
                subMenu.addEventListener('transitionend', listener, listenerOptions);
            }
        }

        clearTimeout(this.#subMenuClosingTimeouts.get(parentMenuItem));

        this.#openMenus.delete(parentMenuItem);
    }

    _eventuallyExpandSubmenusInitially() {
        if (
            this.#expandInitially === true ||
            (this.#expandInitially === 'below' && Foundation.MediaQuery.atLeast(this.#breakpoint) === false) ||
            (this.#expandInitially === 'above' && Foundation.MediaQuery.atLeast(this.#breakpoint))
        ) {
            this.#primaryNav.querySelectorAll('[data-menu-item].is-current-parent').forEach((menuItem) => {
                this._openSubmenu(menuItem);
            });

            if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
                this._sanitizeHeights();
            }
        }
    }

    /** @private */
    _sanitizeHeights() {
        if (this.#subMenus === null) {
            // cache and reverse the submenus.
            // reversing is required to first handle the deepest submenus
            this.#subMenus = [...this.#primaryNav.querySelectorAll('[data-menu-sub]')].reverse();
        }

        this.#subMenus.forEach((subMenu) => {
            if (subMenu.parentElement.dataset.expanded === 'true') {
                // Iterate through children and sum their heights to correctly get the intended height of the submenu.
                // This is required as the submenus' heights are transitioned by CSS and therefore report wrong height
                // when queried directly.
                subMenu.style.height =
                    Array.prototype.map
                        .call(subMenu.children, (child) => {
                            let val;

                            if ('submenuToggle' in child.dataset) {
                                val = 0; // toggle buttons are inline with the link, so they must not count
                            } else if (
                                'menuItem' in child.dataset &&
                                'hasSubmenu' in child.dataset &&
                                child.dataset.expanded === 'true'
                            ) {
                                const styles = window.getComputedStyle(child);

                                val =
                                    parseFloat(styles.getPropertyValue('padding-top')) +
                                    Array.prototype.map
                                        .call(child.children, (grandChild) => {
                                            let val;

                                            if ('submenuToggle' in grandChild.dataset) {
                                                val = 0;
                                            } else {
                                                val = grandChild.scrollHeight;
                                            }

                                            return val;
                                        })
                                        .reduce((prv, cur) => prv + cur);
                            } else if (
                                'menuItem' in child.dataset &&
                                'hasSubmenu' in child.dataset &&
                                child.dataset.expanded !== 'true'
                            ) {
                                const styles = window.getComputedStyle(child);

                                val =
                                    parseFloat(styles.getPropertyValue('padding-top')) +
                                    child.querySelector('a').scrollHeight;
                            } else {
                                val = child.scrollHeight;
                            }

                            return val;
                        })
                        .reduce((prv, cur) => prv + cur) + 'px';
            } else {
                subMenu.style.height = '0px';
            }
        });
    }

    /** @private */
    _onSubmenuParentTouchStart() {
        this.#touch = true;
    }

    /** @private */
    _onSubmenuParentMouseEnter(event) {
        if (this.#touch) {
            this.#touch = false; // due to event-order (see above) this must be reset here to work correctly
            return;
        }

        if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
            return;
        }

        const parentMenuItem = event.target.closest('[data-menu-item][data-has-submenu]');

        event.stopImmediatePropagation();

        clearTimeout(this.#subMenuClosingTimeouts.get(parentMenuItem));

        this._openSubmenu(parentMenuItem);
    }

    /** @private */
    _onSubmenuParentMouseLeave(event) {
        if (this.#touch) {
            return;
        }

        if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
            return;
        }

        const parentMenuItem = event.target.closest('[data-menu-item][data-has-submenu]');

        event.stopImmediatePropagation();

        this._scheduleClosing(parentMenuItem);
    }

    /** @private */
    _onSubmenuKeyDown(event) {
        event.stopImmediatePropagation();

        this.#keyboard = true;

        if (event.key === 'Escape') {
            const parentMenuItem = event.target.closest('[data-menu-item][data-has-submenu][data-expanded="true"]');

            if (parentMenuItem) {
                this._closeSubmenu(parentMenuItem);

                parentMenuItem.querySelector('[data-submenu-toggle]').focus();
            }
        }
    }

    /** @private */
    _onSubmenuKeyUp() {
        this.#keyboard = false;
    }

    /** @private */
    _onSubmenuFocusIn(event) {
        event.stopImmediatePropagation();

        if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
            return;
        }

        clearTimeout(this.#subMenuClosingTimeouts.get(event.target.closest('[data-menu-item][data-has-submenu]')));
    }

    /** @private */
    _onSubmenuFocusOut(event) {
        event.stopImmediatePropagation();

        if (Foundation.MediaQuery.atLeast(this.#breakpoint) === false) {
            return;
        }

        // requestAnimationFrame is necessary to wait for document.activeElement to be updated
        requestAnimationFrame(() => {
            let prevMenu = event.target.closest('[data-menu-item][data-has-submenu]'),
                focusedMenu = document.activeElement;

            // if the focus left the primary navigation component completely, close all open menus
            if (focusedMenu && this.#primaryNav !== focusedMenu && this.#primaryNav.contains(focusedMenu) === false) {
                this.#openMenus.forEach((parentMenuItem) => {
                    this._closeSubmenu(parentMenuItem);
                });
            } else if (this.#keyboard) {
                // close sub-menus when leaving a menu-item via keyboard navigation
                while (prevMenu) {
                    if (prevMenu && prevMenu !== focusedMenu && prevMenu.contains(focusedMenu) === false) {
                        this._closeSubmenu(prevMenu);
                    }

                    prevMenu = prevMenu.parentElement.closest('[data-menu-item]');
                }
            }
        });
    }

    /** @private */
    _onSubmenuToggleClick(event) {
        const parentMenuItem = event.target.closest('[data-menu-item][data-has-submenu]');

        event.stopImmediatePropagation();

        this._toggleSubmenu(parentMenuItem);
    }

    /** @private */
    _scheduleClosing(parentMenuItem) {
        this.#subMenuClosingTimeouts.set(
            parentMenuItem,
            setTimeout(() => {
                // to prevent timing issues, only close actually open menus
                if (this.#openMenus.has(parentMenuItem)) {
                    this._closeSubmenu(parentMenuItem);
                }
            }, this.#closingDelay),
        );
    }

    /** @private */
    _onResize() {
        if (Foundation.MediaQuery.atLeast(this.#breakpoint)) {
            this.#openMenus.forEach((menu) => {
                this._checkAlignment(menu);
            });
        }
    }

    _onBreakpointChange(event, newBreakpoint, oldBreakpoint) {
        // the event is triggered also on initial loading, where no breakpoints are passed in
        if (!newBreakpoint || !oldBreakpoint) {
            return;
        }

        const queries = Foundation.MediaQuery.queries,
            breakpointIndex = queries.findIndex((q) => q.name === this.#breakpoint),
            newBreakpointIndex = queries.findIndex((q) => q.name === newBreakpoint),
            oldBreakpointIndex = queries.findIndex((q) => q.name === oldBreakpoint);

        // when switching between mobile and desktop menus, sanitize the DOM
        if (
            (oldBreakpointIndex < breakpointIndex && newBreakpointIndex >= breakpointIndex) ||
            (oldBreakpointIndex >= breakpointIndex && newBreakpointIndex < breakpointIndex)
        ) {
            this.#primaryNav.querySelectorAll('[data-menu][style]').forEach((menu) => {
                menu.style.height = '';
                menu.style.display = '';
            });

            this.#primaryNav.querySelectorAll('[data-menu-item][data-expanded="true"]').forEach((menuItem) => {
                menuItem.classList.remove('is-expanded');
                menuItem.removeAttribute('data-expanded');
            });
        }
    }
}
