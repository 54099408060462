export async function courses() {
    const backButtons = document.querySelectorAll('[data-back-button]'),
        swimlanes = document.querySelectorAll('[data-swimlane]'),
        courseDates = document.querySelectorAll('[data-course-date]');

    if (backButtons.length) {
        let referrer = document.referrer;

        if (referrer) {
            referrer = new URL(referrer);

            const hideButtons = referrer.hostname !== location.hostname;

            // do not show the back button if not coming from the same site
            backButtons.forEach((button) => (button.hidden = hideButtons));
        }
    }

    if (swimlanes.length) {
        import(/* webpackMode: "lazy" */ './swimlane.async.js').then(({ initSwimlanes }) => {
            initSwimlanes(swimlanes);
        });
    }

    if (courseDates.length) {
        import(/* webpackMode: "lazy" */ './course-date.async.js').then(({ initCourseDates }) => {
            initCourseDates(courseDates);
        });
    }
}
